import React, { createContext, useContext } from "react";

const SiteContext = createContext();

export const useSiteContext = () => {
    return useContext(SiteContext);
};

export const SiteProvider = ({ children }) => {
    const openChatWindow = () => {
        if (window.LiveChatWidget) {
            //window.LiveChatWidget.call('maximize', { messageDraft: "Hi" });
            window.LiveChatWidget.call("maximize");
        } else {
            console.error("LiveChatWidget is not available.");
        }
    };

    const value = {
        openChatWindow,
    };

    return <SiteContext.Provider value={value}>{children}</SiteContext.Provider>;
};
